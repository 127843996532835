import { Button, DarkThemeToggle, Navbar } from "flowbite-react";
import React from "react";

export const NavigationBar = () => {
  return (
    <Navbar fluid className="shadow-md">
      <Navbar.Brand>
        <img src="/favicon.ico" className="mr-3 h-6 sm:h-9" alt="Flowbite React Logo" />
        <span className="self-center whitespace-nowrap text-xl font-semibold dark:text-white">
        Shiva
        </span>
      </Navbar.Brand>
      <div className="flex md:order-2">
        <DarkThemeToggle />
        <Button>Checkout</Button>
        <Navbar.Toggle />
      </div>
      <Navbar.Collapse>
        <Navbar.Link href="#" active>
          Home
        </Navbar.Link>
      </Navbar.Collapse>
    </Navbar>
  );
};
