import React from "react";
import { Slider } from "../../shared/components/Slider/Slider";
export const ChoosePrinter: React.FC = () => {
  return (
    <>
      <div className="container mx-auto w-full">
        <div className="flex flex-col gap-4">
          <div className="flex-auto w-full p-4">
           <Slider />
          </div>
        </div>
      </div>
    </>
  );
};
