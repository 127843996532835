import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import NotFound from "../pages/NotFound/NotFound";
import { HomePage } from "../pages/HomePage/HomePage";
import { ChoosePrinter } from "../pages/ChoosePrinter/ChoosePrinter";
import { SetParameters } from "../pages/SetParameters/SetParameters";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App hasSliceID={true} />,
    errorElement: <NotFound />,
    children: [
      {
        path: "slicer/:sliceID",
        element: <HomePage />,
      },
      {
        path: "slicer/:sliceID/choose-printer",
        element: <ChoosePrinter />,
      },
      {
        path: "slicer/:sliceID/set-parameters",
        element: <SetParameters />,
      },
    ],
  },
  // {
  //   path: "slicer/choose-printer",
  //   element: <ChoosePrinter />,
  //   errorElement: <NotFound />,
  // },
]);
