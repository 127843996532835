import React from "react";
import { UploadFile } from "../../shared/components/UploadFile/Upload";
import { Label, TextInput } from "flowbite-react";
export const HomePage: React.FC = () => {
  return (
    <>
      <div className="container mx-auto w-full">
        <div className="flex flex-col gap-4">
          <div className="flex-auto w-full p-4">
            <div>
              <div className="mb-2 block">
                <Label htmlFor="email1" value="Project name" />
              </div>
              <TextInput
                id="projectname"
                type="text"
                placeholder="Benchy.stl"
                required
              />
            </div>
            <div className="pt-4 pb-3">
            <UploadFile />

            </div>
          </div>
        </div>
      </div>
    </>
  );
};
