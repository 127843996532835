import React from "react";
import { NavigationBar } from "./shared/components/Navbar/Navbar";
import { Flowbite } from "flowbite-react";
import { Outlet } from "react-router-dom";
import { HomePage } from "./pages/HomePage/HomePage";
import { Stepper } from "./shared/components/Stepper/Stepper";

interface ComponentProps {
  hasSliceID: boolean;
}

const App: React.FC<ComponentProps> = ({ hasSliceID }: ComponentProps) => (
  <>
    <Flowbite>
      <NavigationBar />
      <div className="flex-auto w-full p-4">
        <Stepper />
      </div>
      {/* {hasSliceID ? <HomePage /> : <Outlet />} */}
      <Outlet />
    </Flowbite>
  </>
);

export default App;
